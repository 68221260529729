<template>
    <lassoLayout>
        <div class="page-logged-out full_page">
            <div class="content-page">
                <div class="block_logged_out">
                    <div class="spaceship"></div>
                    <div class="description">
                        You’ve successfully logged out!
                    </div>
                    <div class="action_buttons">
                        <router-link :to="{name: 'explore'}" class="right primary_btn small" v-ripple>Home</router-link>
                        <router-link :to="{name: 'login'}" class="right tetriatary_btn small" v-ripple>Log In</router-link>
                    </div>
                </div>
            </div>
        </div>
    </lassoLayout>
</template>

<script>
import lassoLayout from '@/layouts/lassoLayout.vue';
import {mapGetters} from "vuex";

export default {
    name: 'logged-out',
    components: {
        lassoLayout
    },
    data() {
        return {}
    },
    computed: {
	    ...mapGetters({
		    authData: 'AUTH_DATA',
	    }),
    },
    mounted() {
	    if (this.authData.accessToken) {
		    this.$router.push({name: 'explore'});
	    }
    },
    methods: {}
}
</script>

<style scoped>
.page-logged-out {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
}

.content-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure it takes full width */
}

.block_logged_out {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Padding for spacing */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}

.spaceship {
    animation: hover 2s ease-in-out infinite; /* Apply hover animation */
}

@keyframes hover {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px); /* Move up */
    }
    100% {
        transform: translateY(0); /* Move back down */
    }
}

.description {
    margin-bottom: 20px; /* Space between message and buttons */
}

.action_buttons {
    display: flex;
    gap: 10px; /* Space between buttons */
}

</style>
.space-background circle {
    animation: twinkle 3s infinite alternate;
}

@keyframes twinkle {
    0% { opacity: 0.3; }
    100% { opacity: 0.7; }
}

</style>
